import React, { useState, useEffect } from "react";
import "./App.css";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import HomePage from "./screens/homeScreen/homeScreen.component";
import ArabsInSweden from "./screens/arabs_in_sweden/arabs_in_sweden.component";
import UpdateInfo from "./screens/updateinfoScreen/updateinfo.component";
import Help from "./screens/help/help.component";
import { addOpening, updateServerURL } from "./redux/app/app.actions";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";
import arrowup from "./assets/arrow-up.png";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
Modal.setAppElement("#root");

const DEFAULT_CONFIG = {
  trackingId: "UA-176600842-1",
  debug: true,
  gaOptions: {
    cookieDomain: "none",
  },
};

function App() {
  const location = useLocation();
  var subtitle;
  const [modalIsOpen, setIsOpen] = useState(true);
  const dispatch = useDispatch();
  const [reactGaInitialised, setReactGaInitialised] = useState(false);
  const [configs, SetConfigs] = useState([DEFAULT_CONFIG]);
  const history = createBrowserHistory();

const ScrollToTopButton = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Optional: Add smooth scrolling behavior
    });
}

  // Analytics

  const filteredConfigs = () => {
    return configs.filter(({ trackingId: id }) => id);
  };

  const initReactGA = (event) => {
    if (filteredConfigs().length === 0) {
      return;
    }
    console.log("ReactGA.initialize");
    ReactGA.initialize(configs);
    history.listen((location) => {
      console.log(location.pathname);
      ReactGA.set({ page: location.pathname }); // Update the user's current page
      ReactGA.pageview(location.pathname); // Record a pageview for the given page
    });
    // console.log('analytics called');
    setReactGaInitialised({ reactGaInitialised: true });
  };

  function initializeReactGA() {
    ReactGA.initialize("UA-176600842-1");
    ReactGA.pageview("/homepage");
  }

  useEffect(() => {
    console.log("google analytics");
    initializeReactGA();
    console.log("*******************************");
  }, []);

  // End of Analytics
  dispatch(updateServerURL());
  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <div className="HelloModal">
      {location.pathname == "/" && (
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <h2
            style={{ textAlign: "right", direction: "rtl" }}
            ref={(_subtitle) => (subtitle = _subtitle)}
          >
            مرحبا
          </h2>
          <div style={{ textAlign: "right", direction: "rtl" }}>
            <p>
              نصائح الاستخدام للترجمة من العربية للسويدية للحصول على أفضل
              النتائج:
            </p>
            <ul>
              <li>- للبحث المتطور و الحصول على خيارات عديدة اضغط "ابحث"</li>
              <li>
                - في حال عدم ظهور الكلمة التي تبحث عنها ان كنت قد كتبتها مضافاً
                لها ألف و لام التعريف ابحث عن نفس الكلمة بعد حذف ألف و لام
                التعريف، و العكس صحيح
              </li>
              <li>
                - اكتب أول ثلاثة أحرف من الكلمة المراد البحث عنها و اعطي محرك
                البحث بضعة ثوان و ستظهر لك قائمة بكل الكلمات التي تبدأ بتلك
                الأحرف و حينها تختار كلمتك من القائمة بالضغط عليها
              </li>
            </ul>
            <button onClick={closeModal}>اغلاق</button>&nbsp;&nbsp;
            <button onClick={closeModal}>Stäng</button>
          </div>
        </Modal>
      )}
      {/* <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/update" component={UpdateInfo} />
        <Route exact path="/عربي_في_السويد" component={ArabsInSweden} />
        <Route exact path="/help" component={Help} />
      </Switch> */}
      <HomePage />
<div className="uparrow" onClick={()=>ScrollToTopButton()}>
              <img className="arrowupImage" src={arrowup} />
</div>
    </div>
  );
}

export default App;
