import React, { useState, useEffect } from "react";
import "./header.styles.scss";
import {
  Container,
  Row,
  Col,
  Navbar,
  Collapse,
  Nav,
  NavItem,
  NavbarToggler,
  NavbarBrand,
} from "reactstrap";
import Logo from "../logo/Logo.component";
import { FaHome, FaHeadphones, FaBookReader, FaUserAlt } from "react-icons/fa";
import { Link, animateScroll as scroll } from "react-scroll";
import { BrowserRouter as Router, Link as MoveLink } from "react-router-dom";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const handleClick = () => {
    setIsOpen(false);
  };
  return (
    <div className="header">
      <Navbar color="light" light expand="xl">
        <NavbarBrand>
          <Link
            activeClass="active"
            to="/"
            onClick={handleClick}
            spy={true}
            smooth={true}
            offset={-112}
            duration={500}
          >
            {" "}
            <Logo />
          </Link>
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            {/*  <NavItem>
              <MoveLink activeClass="active" to="/عربي_في_السويد"  >
                <div className="se se-bold">Arab i Sverige</div>
                <div className="ar ar-bold">عربي في السويد</div>
              </MoveLink>

            </NavItem> */}
            <NavItem>
              <Link
                activeClass="active"
                to="contactus"
                spy={true}
                onClick={handleClick}
                smooth={true}
                offset={-100}
                duration={500}
              >
                <div className="se se-bold">Kontakta oss</div>
                <div className="ar ar-bold">تواصل معنا</div>
              </Link>
            </NavItem>
            <NavItem>
              <Link
                activeClass="active"
                to="books"
                spy={true}
                onClick={handleClick}
                smooth={true}
                offset={-100}
                duration={500}
              >
                <div className="se se-bold">Böcker</div>
                <div className="ar ar-bold">الكتب</div>
              </Link>
            </NavItem>
            <NavItem>
              <Link
                activeClass="active"
                to="usedterms"
                onClick={handleClick}
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                <div className="se se-bold">
                  Användbara fraser och facktermer
                </div>
                <div className="ar ar-bold">
                  عبارات و مصطلحات شائعة الاستعمال
                </div>
              </Link>
            </NavItem>
            <NavItem>
              <Link
                activeClass="active"
                to="aboutus"
                onClick={handleClick}
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                <div className="se se-bold">Om oss</div>
                <div className="ar ar-bold">من نحن</div>
              </Link>
            </NavItem>
            <NavItem>
              <Link
                activeClass="active"
                to="testimonials"
                onClick={handleClick}
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                <div className="se se-bold">Vad sa språkexperter</div>
                <div className="ar ar-bold">ماذا قال الخبراء</div>
              </Link>
            </NavItem>
            <NavItem>
              <Link
                activeClass="active"
                to="/"
                onClick={handleClick}
                spy={true}
                smooth={true}
                offset={-112}
                duration={500}
              >
                <div className="se se-bold">Ordlistan</div>
                <div className="ar ar-bold">القاموس</div>
              </Link>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default Header;
