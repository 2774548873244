import React, { useState } from "react";
import Header from "../../components/header/header.component";
import Introduction from "../../components/introduction/introduction.component";
import Translate from "../../components/translate-box/translate-box.component";
import { Element } from "react-scroll";
import LanguageTabs from "../../components/language-tabs/language-tabs.component";
import Mobile_Box from "../../components/mobile_box/mobile_box.component";
import InfoSection from "../../components/info-section/info-section.component";
import Boxes from "../../components/boxes/boxes.component";
import AboutUs from "../../components/about-us/about-us.component";
import Books from "../../components/books/books.component";
import ContactUs from "../../components/contact-us/contact-us.component";
import Footer from "../../components/footer/footer.component";
import UsedTermsCategories from "../../components/used-terms-categories/used-terms-categories.component";
import Testemonials from "../../components/testemonials/testemonials.component";
import AskDoctor from "../../components/ask-doctor/ask-doctor.component";
import Register from "../../components/register/register.component";
import { useSelector } from "react-redux";
import Login from "../../components/login/login.component";
import ForgotPassword from "../../components/forgot-password/forgot-password.component";
import ResetPassword from "../../components/reset-password/reset-password.component";
import "./home.styles.scss";
import { Container } from "reactstrap";
import { useLocation } from 'react-router-dom';

const HomePage = () => {
  const app = useSelector((state) => state.app);
  const [opentab, setOpenTab] = useState("register");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let showResetPassword = false;
  if(location.pathname==='/reset-password/'){
    const email = searchParams.get('email');
    const code = searchParams.get('code');
    if(email && code){
      showResetPassword=true; 
      setTimeout(()=>setOpenTab('resetPassword'), 100);
      
    }
  }
  return (
    <div className="App">
      <Header />
      {app.openings >= 5 && app.token === "" ? (
        <Element name="Register" className="element registerBox">
          <Container>
            <div className="loginTabs">
              <div
                className={
                  opentab === "login" ? "tab login active" : "tab login"
                }
                onClick={() => setOpenTab("login")}
              >
                LOGIN
              </div>
              <div
                className={
                  opentab === "register"
                    ? "tab register active"
                    : "tab register"
                }
                onClick={() => setOpenTab("register")}
              >
                REGISTER
              </div>
    <div
                className={
                  opentab === "forgotPassword"
                    ? "tab forgotPassword active"
                    : "tab forgotPassword"
                }
                onClick={() => setOpenTab("forgotPassword")}
              >
                Forgot Password
              </div>
{showResetPassword && 
    <div
                className={
                  opentab === "resetPassword"
                    ? "tab resetPassword active"
                    : "tab resetPassword"
                }
                onClick={() => setOpenTab("resetPassword")}
              >
                Reset Password
              </div>}
            </div>
          </Container>
          {opentab === "login" && <Login />}
          {opentab === "register" && <Register />}
          {opentab === "forgotPassword" && <ForgotPassword />}
          {opentab === "resetPassword" && <ResetPassword />}


        </Element>
      ) : (
        <Element name="translator" className="element">
          <Introduction />
          <LanguageTabs />
          <Translate />
        </Element>
      )}

      <br />
      <br />

      {/*  <InfoSection
          arabic="معلومات عن القاموس"
          swedish="Information om ordboken"
        /> */}

      <Element name="aboutus" className="element">
        <AboutUs />
      </Element>
      <br />
      <br />
      <Element name="testimonials" className="testimonials">
        <Testemonials />
      </Element>
      <Element name="usedterms" className="usedterms">
        <InfoSection
          arabic="عبارات و مصطلحات شائعة الاستعمال"
          swedish="Användbara fraser och facktermer"
        />
        <UsedTermsCategories />
      </Element>
      <Element name="books" className="element">
        <InfoSection arabic="الكتب" swedish="Böcker" />
        <Books />
      </Element>
      <Element>
        <InfoSection arabic="حمل التطبيق" swedish="Ladda ner applikation" />
        <Mobile_Box />
      </Element>
      <Boxes />

      <Element name="contactus" className="element">
        <InfoSection arabic="تواصل معنا" swedish="Kontakta oss" />
        <ContactUs />
      </Element>
      <Footer />
      <AskDoctor />
    </div>
  );
};

export default HomePage;
