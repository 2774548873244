import React from "react";
import "./language-tabs.styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { TranslationType } from "../../redux/translationType.enum";
import { setTranslationType } from "../../redux/app/app.actions";
import { Container } from "reactstrap";
function LanguageTabs() {
  const dispatch = useDispatch();
  const translationType = useSelector((state) => state.app.translationType);
  return (
    <div className="LanguageTabs">
      <Container className="LanguageTabsContainer">
        <div className="tabs">
          <div className="tab">Välj översättningsspråk</div>
          <div
            onClick={() =>
              dispatch(setTranslationType(TranslationType.SWEDISH_ARABIC))
            }
            className={
              translationType === TranslationType.SWEDISH_ARABIC
                ? "tab pointer active"
                : "tab pointer"
            }
          >
            Swedish - عربي
          </div>
          <div
            onClick={() =>
              dispatch(setTranslationType(TranslationType.ENGLISH_ARABIC))
            }
            className={
              translationType === TranslationType.ENGLISH_ARABIC
                ? "tab pointer active"
                : "tab pointer"
            }
          >
            English - عربي
          </div>
          <div
            onClick={() =>
              dispatch(setTranslationType(TranslationType.SWEDISH_ENGLISH))
            }
            className={
              translationType === TranslationType.SWEDISH_ENGLISH
                ? "tab pointer active"
                : "tab pointer"
            }
          >
            Swedish - English
          </div>
          <div className="tab">اختر لغة الترجمة</div>
        </div>
      </Container>
    </div>
  );
}

export default LanguageTabs;
