import React, { useState, useRef, useEffect } from "react";
import "./ask-doctor.styles.scss";
import QuestionIcon from "../../assets/whoweare.jpg";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { token } from "../constants";
import TickIcon from "../../assets/tick.png";
const AskDoctor = () => {
  const app = useSelector((state) => state.app);
  const [modal, setModal] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const toggle = () => setModal(!modal);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (values) => {
    const jsonValues = JSON.stringify(values);
    fetch(`${app.serverUrl}/ask-doctor`, {
      method: "POST",
      body: jsonValues,
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    }).then((response) => {
      setSubmitted(true);
      console.log("response", response);
    });
  };

  return (
    <div className="askDoctor">
      <div className="button" onClick={toggle}>
        <div className="questionIconBox">
          <img src={QuestionIcon} className="questionIcon" />
        </div>
        اسأل د.حسام
      </div>
      <Modal isOpen={modal} toggle={toggle} className="ModalBox modal-lg">
        <ModalHeader style={{ textAlign: "right", direction: "rtl" }}>
          اسال الدكتور حسام القبلاوي
        </ModalHeader>
        <ModalBody>
          {submitted && (
            <div style={{ textAlign: "center" }}>
              <img
                src={TickIcon}
                style={{ width: "100px", marginTop: 30, marginBottom: 30 }}
              />
              <p style={{ width: "100%", marginTop: 30, marginBottom: 30 }}>
                شكرا لك، سوف نقوم بالرد على سؤالك في اقرب وقت.
              </p>
            </div>
          )}
          {!submitted && (
            <>
              <div
                className="askDescription"
                style={{ textAlign: "right", marginBottom: 30, direction:'rtl' }}
              >
 من خلال هذه النافذة يمكنك سؤال الدكتور حسام القبلاوي حول كل ما يتعلق باللغة العربية، السويدية، والانكليزية و سوف يرد د. حسام على جميع استفساراتكم في اسرع وقت ممكن.


              </div>
              <div className="questionForm">
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <input
                    name="name"
                    className="InputName Input"
                    placeholder="الاسم"
                    required
                    {...register("name")}
                    style={{
                      width: "100%",
                      margin: "0px 5px",
                      textAlign: "right",
                      direction: "rtl",
                      border: "1px solid #373737",
                      borderRadius: 10,
                      padding: 10,
                    }}
                  />
                  <br />
                  <input
                    name="email"
                    className="InputEmail Input"
                    placeholder="البريد الالكتروني"
                    required
                    {...register("email")}
                    style={{
                      width: "100%",
                      margin: "0px 5px",
                      textAlign: "right",
                      direction: "rtl",
                      border: "1px solid #373737",
                      borderRadius: 10,
                      padding: 10,
                    }}
                  />
                  <br />

                  <textarea
                    id="description"
                    {...register("message")}
                    required
                    className="InputMessage Input"
                    placeholder="السؤال"
                    style={{
                      width: "100%",
                      margin: "0px 5px",
                      textAlign: "right",
                      direction: "rtl",
                      border: "1px solid #373737",
                      padding: 10,
                      borderRadius: 10,
                    }}
                  />

                  <div className="SubmitComment" style={{ marginTop: 30 }}>
                    <Button>Send - ارسل</Button>
                  </div>
                </form>
              </div>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AskDoctor;
