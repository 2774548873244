import React, { useState } from "react";
import "./register.styles.scss";
import { Container } from "reactstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import { countries } from "./countries-list.tsx";
import { insertToken } from "../../redux/app/app.actions";

function Register() {
  const app = useSelector((state) => state.app);
  const [error, setError] = useState();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (values) => {
    if (values.password !== values.confirmPassword) {
      setError(`Password didn't match / كلمة المرور غير متطابقة`);
      return;
    }
    delete values.confirmPassword;
    // const jsonValues = JSON.stringify(values);
    fetch(`${app.serverUrl}/users`, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.access_token) {
          dispatch(insertToken(response.access_token));
        } else {
          setError(
            `This user is already registered, please log in instead / هذا المستخدم مسجل بالفعل ، يرجى تسجيل الدخول بدلاً من ذلك`
          );
        }
      });
  };
  return (
    <div className="Register">
      <Container>
        <>
          <div
            className="askDescription"
            style={{ textAlign: "right", marginBottom: 30 }}
          >
           Register now for free! | سجل نفسك للإستخدام المجاني للقاموس
          </div>
          <div
            className="askDescription"
            style={{ textAlign: "right", marginBottom: 30 }}
          >
            عند التسجيل ستستطيع استخدام كل ميزات القاموس مجاناً و بشكل أوسع و
            أشمل و سيمكنك ذلك من الحصول على نتائج دقيقة بشكل محترف. كما سيمكنك
            من الحصول على كل التحديثات و الاضافات و استخدام خدمة إسأل د.حسام
            للحصول على رد لأي استفسار أو أي كلمة تفتقدها في القاموس
          </div>
          <div className="questionForm">
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                flexWrap: "wrap",
              }}
            >
              <input
                name="fullName"
                className="InputName Input"
                placeholder="Name /الاسم"
                required
                {...register("fullName")}
                style={{
                  width: "49%",
                  margin: "0px 5px",
                  textAlign: "right",
                  direction: "rtl",
                  border: "1px solid #373737",
                  borderRadius: 10,
                  padding: 5,
                  height: "50px",
                }}
              />
              <input
                name="email"
                className="InputEmail Input"
                placeholder="البريد الالكتروني"
                required
                {...register("email")}
                style={{
                  width: "49%",
                  margin: "0px 5px",
                  textAlign: "right",
                  direction: "rtl",
                  border: "1px solid #373737",
                  borderRadius: 10,
                  padding: 5,
                  height: "50px",
                }}
              />
              <input
                name="mobile"
                className="InputMobile Input"
                placeholder="رقم الهاتف / Mobile Number"
                required
                {...register("mobile")}
                type="number"
                style={{
                  width: "49%",
                  margin: "0px 5px",
                  textAlign: "right",
                  direction: "rtl",
                  border: "1px solid #373737",
                  borderRadius: 10,
                  padding: 5,
                  height: "50px",
                  marginTop: "10px",
                }}
              />
              <select
                {...register("country")}
                name="country"
                className="InputMobile Input"
                placeholder="Country / البلد"
                required
                style={{
                  width: "49%",
                  margin: "0px 5px",
                  textAlign: "right",
                  direction: "rtl",
                  border: "1px solid #373737",
                  borderRadius: 10,
                  padding: 5,
                  height: "50px",
                  marginTop: "10px",
                  color: "grey",
                }}
              >
                <option value={""}>Country / البلد</option>
                {countries.map((country, index) => (
                  <option value={country.name} key={`country_${index}`}>
                    {country.name}
                  </option>
                ))}
              </select>
              <div className="password">
                <input
                  name="password"
                  className="InputPassword Input"
                  placeholder="كلمة السر / Password"
                  required
                  {...register("password")}
                  type="password"
                  style={{
                    width: "30%",
                    margin: "0px 5px",
                    textAlign: "right",
                    direction: "rtl",
                    border: "1px solid #373737",
                    borderRadius: 10,
                    padding: 10,
                  }}
                />{" "}
                <input
                  name="confirmPassword"
                  className="InputPassword ConfirmPassword Input"
                  placeholder="تاكيد كلمة السر / Confirm Password"
                  required
                  type="password"
                  {...register("confirmPassword")}
                  style={{
                    width: "30%",
                    margin: "0px 5px",
                    textAlign: "right",
                    direction: "rtl",
                    border: "1px solid #373737",
                    borderRadius: 10,
                    padding: 10,
                  }}
                />
              </div>
              <div className="error">{error && error}</div>
              <div className="note">We process your personal data in accordance with the General Data Protection Regulation (GDPR)</div>
              <div className="SubmitComment" style={{ marginTop: 30 }}>
                <Button>Register - سجل</Button>
              </div>
            </form>
          </div>
        </>
      </Container>
    </div>
  );
}

export default Register;
