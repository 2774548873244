import React,{useState,useEffect} from 'react';
import './footer.styles.scss';
import { Container, Row, Col,Button } from 'reactstrap';
import { useSelector } from 'react-redux';
const Footer = () =>{
    const app = useSelector(state=>state.app);

return(
 <div className="Footer">
<Container>
<Row>
<Col className="se se-light">Copyright 2020 Hussam Alkoblawi</Col>
<Col className="ar ar-light">kamosarabi.se جميع الحقوق محفوظة </Col>
</Row>
</Container>


 </div>
)   
}

export default Footer;