import TranslateActionTypes from "./translator.types";
import AppActionTypes from "../app/app.types";
export const SetSearchResult = (SearchResult) => {
  return {
    type: TranslateActionTypes.SET_SEARCH_RESULT,
    payload: SearchResult,
  };
};

export const clearResults = () => ({
  type: AppActionTypes.CLEAR_RESULTS,
});
