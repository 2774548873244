import React from "react";
import "./mobile_box.styles.scss";
import mobileImage from "../../assets/mobile.png";
import AppleIcon from "../../assets/apple_icon.png";
import AndroidIcon from "../../assets/android_icon.png";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
function Mobile_Box() {
  return (
    <Container>
      <div className="mobile_box">
        <div className="mobile_image mobile">
          <img src={mobileImage} />
        </div>
        <div className="leftSec">
          <h1>Ladda ner vår mobilapplikation</h1>
          <a href="https://apps.apple.com/sa/app/kamosarabi/id1535023042">
            <img src={AppleIcon} />{" "}
            <div className="downloadLabel">Download IOS Application</div>
          </a>
        </div>
        <div className="mobile_image desktop">
          <img src={mobileImage} />
        </div>
        <div className="rightSec">
          <h1>قم بتنزيل تطبيق الهاتف المحمول الخاص بنا</h1>
          <a href="https://play.google.com/store/apps/details?id=com.kamosarabi&hl=en&gl=US">
            <img src={AndroidIcon} />{" "}
            <div className="downloadLabel">Download Android Application</div>
          </a>
        </div>
      </div>
    </Container>
  );
}

export default Mobile_Box;
