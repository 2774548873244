import React, { useState, useEffect } from "react";
import "./boxes.styles.scss";
import { Container, Row, Col } from "reactstrap";
import { useSelector } from "react-redux";
import Box from "../box/box.component";
import { token } from "../constants/index";
import { Data } from "./data";

const Boxes = () => {
  const [boxes, setBoxes] = useState(Data);
  const app = useSelector((state) => state.app);

  useEffect(() => {}, [boxes]);

  return (
    <div className="Boxes">
      <Container>
        <Row>
          {boxes.map((element, index) => (
            <Col xs={12} md={12} key={`books_${index}`}>
              <Box data={element} language="arabic" />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Boxes;
