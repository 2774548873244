import AppActionTypes from "./app.types";
import { TranslationType } from "../translationType.enum";
const INITIAL_STATE = {
  serverUrl: "",
  addtohomescreen: false,
  translationType: TranslationType.SWEDISH_ARABIC,
  token: "",
  openings: 0,
};

const appReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AppActionTypes.SET_OPENED_CATEGORY:
      return {
        ...state,
        openedCategory: action.payload.name,
      };
    case AppActionTypes.UPDATE_SERVER_URL:
      return {
        ...state,
        serverUrl: action.payload,
      };
    case AppActionTypes.SET_TRANSLATION_TYPE:
      return {
        ...state,
        translationType: action.payload,
      };
    case AppActionTypes.ADD_OPENING:
      return {
        ...state,
        openings: state.openings + 1,
      };
    case AppActionTypes.TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    default:
      return state;
  }
};

export default appReducer;
