import React, { useState, useEffect } from "react";
import "./static-page.styles.scss";
import { Container, Row, Col, Button } from "reactstrap";
import { useSelector } from "react-redux";
import Comments from "../comments/comments.component";
const StaticPage = ({ data, language }) => {
  const app = useSelector((state) => state.app);
  console.log("StaticPage", data);
  return (
    <div className="staticPage">
      {/*  <div className="headerimage">
        <img src={data.icon} />
      </div> */}
      <Row className="title">
        <Col
          className="ar ar-bold"
          dangerouslySetInnerHTML={{ __html: data.title_ar }}
        ></Col>
      </Row>
      <Row className="introduction">
        {/*  <Col
          className="se se-regular"
          dangerouslySetInnerHTML={{ __html: data.introduction_se }}
        ></Col> */}
        <Col
          className="ar ar-regular"
          dangerouslySetInnerHTML={{ __html: data.Introduction_ar }}
        ></Col>
      </Row>
      {data.body_ar && (
        <Row>
          <Col
            className="se se-light"
            dangerouslySetInnerHTML={{ __html: data.body_se }}
          ></Col>
          <Col
            className="ar ar-light"
            dangerouslySetInnerHTML={{ __html: data.body_ar }}
          ></Col>
        </Row>
      )}

      <Comments />
    </div>
  );
};

export default StaticPage;
