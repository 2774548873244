import React from 'react';
import ArabsInSwedenHeader from '../../components/arabs-in-sweden-header/arabs-in-sweden-header.component';
import Footer from '../../components/footer/footer.component';
import Posts from '../../components/posts/posts.component';
import { Container, Row, Col, Button } from 'reactstrap';
import './arabs_in_sweden.styles.scss';
import ArabsinSwedenIntroduction from '../../components/arabic-in-sweden-introduction/arabic-in-sweden-introduction.component';
class ArabsInSweden extends React.Component {


    render() {


        return (<div className="arabs_in_sweden">
            <ArabsInSwedenHeader />
            <Container>
                <ArabsinSwedenIntroduction />
                <div className="separator">&nbsp;</div>

                <Posts />

            </Container>
            <Footer />
        </div>)


    }




}

export default ArabsInSweden;