import React, { useState, useEffect } from "react";
import "./contact-us.styles.scss";
import { Container, Row, Col, Button } from "reactstrap";
import { useSelector } from "react-redux";
import Facebook from "../../assets/facebook.e6258e09.png";
import Website from "../../assets/website.9d3ef8d9.png";
import ContactUsImage from "../../assets/contactus_cf5c47d64b.png";
import { Data } from "./data";

const ContactUs = ({ data, language }) => {
  const app = useSelector((state) => state.app);
  const [contactus, Setcontactus] = useState(Data);

  useEffect(() => {}, [contactus]);
  return (
    <div className="Contactus">
      <Container>
        <Row>
          <Col xs={12} md={4}>
            <div
              className="aboutUs_sec_1 se se-bold"
              dangerouslySetInnerHTML={{ __html: contactus.introduction_se }}
            ></div>
            <br />
            <div
              className="aboutUs_sec_2 se se-light"
              dangerouslySetInnerHTML={{ __html: contactus.body_se }}
            ></div>
          </Col>
          <Col xs={12} md={4} className="contacticondiv">
            <img src={ContactUsImage} className="Contactus_Icon" width="100%" />
            <div className="socialLinks">
              <div className="facebookLink">
                <img src={Facebook} className="facebook_icon" />
                <a
                  href="https://www.facebook.com/nblbokforlag/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Facebook
                </a>
              </div>
              <div className="websiteLink">
                <img src={Website} className="website_icon" />
                <a
                  href="http://www.essweden.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  Website
                </a>
              </div>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div
              className="aboutUs_sec_1 ar ar-bold"
              dangerouslySetInnerHTML={{ __html: contactus.Introduction_ar }}
            ></div>
            <br />
            <div
              className="aboutUs_sec_2 ar ar-light"
              dangerouslySetInnerHTML={{ __html: contactus.body_ar }}
            ></div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactUs;
