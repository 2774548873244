import CommentsLogo from "../../assets/comments.png";
import WhoWeAre from "../../assets/whoweare.jpg";
import Introduction from "../../assets/introduction.png";
export const Data = [
  {
    image: WhoWeAre,
    icon: Introduction,
    title_se: "Skriv en kommentar",
    title_ar: "اكتب تعليقا",
    Introduction_ar:
      "اكتب تعليقاً كي يُساعدنا على تحسين وتطوير القاموس لفائدة الجميع. نتقبل بصدر رحب كل أنواع النقد. لكن دون شك تحفزنا التعليقات الإيجابية والبناءة على المثابرة في العمل من أجل انتاج قاموساً عالِ الجودة والدقة",
    introduction_se:
      "Skriv gärna en kommentar för att hjälpa oss kunna förbättra ordboken för allas nytta. Naturligtvis tar vi med glädje emot all kritik. Men utan tvekan uppmuntrar positiva och konstruktiva kommenterar oss att anstränga oss mer för att skapa en höggradig ackurat ordbok.",
  },
  /* {
    image: CommentsLogo,
    icon: Introduction,
    title_se: "Introduktion",
    title_ar: "المقدمة",
    Introduction_ar: `حسام القبلاوي فلسطيني الأصل، لبناني المولد سويدي الجنسية. ولد في بيروت
         لؤلؤة الشرق حيث أنهى دراسته المدرسية في مدرسة الفاروق عمر ابن 
         الخطاب التابعة لجمعية المقاصد الإسلامية. حاصل على مؤهلات علمية عُليا من القاهرة والسويد. حاصل على درجة البكالوريوس في العلوم و درجة الماجستير و
        الدكتوراه في علم المعادن`,
    introduction_se:
      "Mitt livsverk till samhället.Gigantiska Ordboken Ca 500 000 ord & termer Svenska till arabisk Arabiska till svenska Fil.dr. Hussam Alkoblawi",
    body_se: `Nu har det gått ca 25 år sedan jag gav ut min första ordlista, och därför måste jag hävda att min erfarenhet, mina kunskaper och språkfärdigheter i de båda språken grundligt har förbättrats. Naturligtvis har jag insett och förstått de brister och misstag jag gjorde när jag skrev mina tidigare utgivna ordböcker. Därför kan jag idag bestämt påstå att denna ordlista, GIGANTISKA ORDBOKEN, är utarbetad på så sätt att fel och misstag helt skall kunna uteslutas. Min förhoppning är att den uppfyller alla era förväntningar. 

        Ordlistan är utarbetad efter de modernaste riktlinjer och rekommendationer inom lexikologin och översättningsteorier. 
        
        Den är anpassad efter allas behov, från studerande på SFI, studerande på universitetsnivå och tolkaspiranter, till erfarna tolkar och översättares. 
        
        Att denna ordlista blev verklighet beror först och främst på all uppmuntran och stöd jag fått av de som har visat sin uppskattning för mina tidigare utgivna ordböcker och tackat genom att skriva några rader med uppskattande och berömmande ord. Detta gav mig en rejäl energikick och hopp om att mina små insatser förhoppningsvis kommer att vara till stor nytta för människorna, i synnerhet för främjandet av de svenska och arabiska språken. Utöver detta har jag alltid varit övertygad om att nyckeln till karriär, integration, ömsesidigt samförstånd och fredlig samlevnad mellan olika folkgrupper är att de talar samma språk. Därför introducerar jag denna tjänst som kostade mig hela tjugofem år av ansvarsfullt och seriöst arbete med långa nätter och noggrann forskning, helt kostnadsfri till samhället. 
        
        Jag hoppas att jag verkligen har lyckats samla ihop de ord, fraser, uttryck och termer som ni förväntar er hitta i ordlistan med rätt, nogrann och ansvarsfull översättning.   
        
        Jag är mycket glad och stolt över att få bidra med min lilla insats för att främja lärandet av svenska språket.`,
    body_ar: `عمل مدرساً وباحثاً في معهد علوم الأرض، كلية العلوم، جامعة لوند. عمل أيضاً مدرساً لمادة الترجمة في كلية الآداب واللغات، جامعة لوند ومحاضراً في قانون
          المدارس للوافدين الجُدد من المعلمين في كلية المُعلمين جامعة مالمو. في العام 1992 أصبح أول مترجماً محلفاً بين السويدية والعربية في منطقة جنوب السويد
          ليصبح فيما بعد مترجماً محلفاً متخصصاً في القانون ويعمل مدرساً للترجمة ومحاضراً في قضايا المجتمع وتلاقي الحضارات و حاصل على دورات حرة في
          دراسات الشرق الأوسط و عادات الشعوب.
          يعمل في مجال الكتابة و التأليف حيث أصدر العديد من القواميس والكتب المتخصصة والروايات.
          أول من أسس مدرسة الترجمة في مدرسة ستودية فراميندت بالتعاون مع إدارة المدرسة في مالمو.
          عمل مدرساً و خبيراً لغوياً لمادة الترجمة في مدرسة ميدبوريار سكولان في مالمو و مدارس أخرى في مدن مختلفة
          من الرواد الأوائل المشاركين في تأسيس مهرجان مالمو للسينما العربية
          
          مؤسس و رئيس اول حزب سياسي في السويد تتكون قيادته بأكملها من سويديين من أصول اجنبية
          مؤسسس و مالك مجموعة ايسترن ستار للانتاج الفني و كذلك سلسلة متاجر ميدو التي نالت على إعجاب و اطراء رئيس وزراء السويد الأسبق يوران بيرسون
          مؤسس ومالك دار الشمال`,
  }, */
];
