import React, { useState, useEffect } from "react";
import "./english-box.styles.scss";
import {
  Container,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from "reactstrap";
import Autocomplete from "react-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import {
  SetSearchResult,
  clearResults,
} from "../../redux/translator/translator.actions";
import { token } from "../constants/index";
import { cleanText, stripHtmlTags } from "../../utils/index";
import { TranslationType } from "../../redux/translationType.enum";
import { addOpening } from "../../redux/app/app.actions";

const EnglishTranslationBox = () => {
  const app = useSelector((state) => state.app);
  ////console.log("ServerUrl",app.serverUrl);
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(SetSearchResult(""));
    setSuggestions([]);
    if (value.length > 0) {
      const timer = setTimeout(() => {
        console.log(
          `${app.serverUrl}/phrases/search/startWith/english/${value}`
        );
        let url = `${app.serverUrl}/phrases/search/startWith/english/${value}`;
        fetch(url, {
          method: "GET",
          headers: new Headers({
            Accept: "application/json",
            Authorization: token,
          }),
        })
          .then((res) => res.json())
          .then((response) => {
            dispatch(addOpening());
            let newsuggestions = [];

            let filteredResults = response.filter((val) =>
              String(
                val.english
                  .replace(/<[^>]+>/g, "")
                  .toString()
                  .toLowerCase()
              ).startsWith(value.toString().toLowerCase())
            );
            //console.log("filteredResults",filteredResults);

            filteredResults.map((element) => {
              newsuggestions.push({
                label: cleanText(element.english),
                id: element.id,
              });
            });
            console.log("response", newsuggestions);

            setSuggestions(newsuggestions);
          });
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [value]);

  const search = (searchText) => {
    //console.log("searchText", searchText);
    let id = searchText.substr(0, searchText.indexOf("*"));
    //console.log('id',id);
    let newSearchtext = searchText.substr(searchText.indexOf("*") + 1);
    setValue(newSearchtext);
    fetch(`${app.serverUrl}/phrases/${id}`, {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        Authorization: token,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        dispatch(SetSearchResult(response));
      });
  };

  const clearText = () => {
    setValue("");
    dispatch(clearResults());
  };
  const searchByButton = () => {
    if (value) {
      const query = encodeURIComponent(value);
      fetch(`${app.serverUrl}/phrases/exact-phrase/english/${query}`, {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          Authorization: token,
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.length > 0) {
            dispatch(SetSearchResult(response));
          }
        });
    }
  };

  return (
    <div className="swedishToarabic">
      <div className="autocomplete">
        <button className="searchBtn" onClick={searchByButton}>
          Search
        </button>

        <Autocomplete
          getItemValue={(item) => item.id + "*" + item.label}
          items={suggestions}
          renderItem={(item, isHighlighted) => {
            const styledLabel = item.label.replace(
              value,
              `<span class="bold">${value}</span>`
            );
            return (
              <div
                key={"autocomplete_swedish_" + Math.random()}
                className="se se-light insideautocomplete"
                dangerouslySetInnerHTML={{ __html: styledLabel }}
              ></div>
            );
          }}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onSelect={(val) => search(val)}
          key="field_1002"
          inputProps={{
            placeholder: "for advanced search and more results press Search",
          }}
        />
        {value.length > 0 && (
          <button className="closeBtn" onClick={clearText}>
            Clear text
          </button>
        )}
      </div>
    </div>
  );
};

export default EnglishTranslationBox;
