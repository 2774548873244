import React from 'react';
import ArabsInSwedenHeader from '../../components/arabs-in-sweden-header/arabs-in-sweden-header.component';
import Footer from '../../components/footer/footer.component';
import Posts from '../../components/posts/posts.component';
import Header from '../../components/header/header.component';
import { Container, Row, Col, Button } from 'reactstrap';
import './help.styles.scss';


class Help extends React.Component {


    render() {


        return (<div className="helpBody">
            <Header />
            <Container>


            </Container>
            <Footer />
        </div>)


    }




}

export default Help;