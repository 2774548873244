import React,{useState,useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";

class UpdateInfo extends React.Component {
    constructor(){
        super();
        this.state = {
			articles:{},
		}
	
    }
    
	componentDidMount(){

		this.loadArticles();

	}

    loadArticles(){

		fetch('http://157.175.57.19:8080/articles',
        {
            method : 'GET',
            headers :{
                'Content-Type': 'application/json',
            }
        })
        .then(response => response.json())
         .then(data => {
            this.setState({articles:data})

        })
        .catch(function(err)
    {
        console.log(err)
	});
	
    }
    

    render(){


        return( <div className="App">
      
      {this.state.articles.length>0 &&
      
      this.state.articles.map(element=>

        <form onSubmit={this.handleSubmit}>

        <div>
        <input type="textarea" 
          name="textValue"
          onChange={this.handleChange}
          value={element.swedish}
        />
          </div>
        <div>
        <input type="textarea" 
          name="textValue"
          onChange={this.handleChange}
          value={element.arabic}
        />
            </div>

        <input type="submit" value="Submit" />
      
      </form>
         
      )}

    </div>)


    }




}

export default UpdateInfo;