import React, { useState, useEffect } from "react";
import "./book.styles.scss";
import { Link } from "react-router";
import { Container, Row, Col, Button } from "reactstrap";
import { useSelector } from "react-redux";
import pdf from "../../assets/pdf.png";
const Book = ({ data }) => {
  const app = useSelector((state) => state.app);
  useEffect(() => {}, []);
  return (
    <div className="Book">
      <Container>
        <div
          className={
            data.Language == "Swedish"
              ? "swedish book se se-regular"
              : "arabic book ar ar-regular"
          }
        >
          <Row>
            <Col xs={12} md={3}>
              {data.image && <img src={data.image} />}
            </Col>
            <Col xs={12} md={9}>
              <div className="book_content">
                <div
                  className="book_body"
                  dangerouslySetInnerHTML={{ __html: data.body }}
                ></div>
                <div className="book_pdf">
                  {data.Language == "Swedish" ? (
                    <a href={`/books/${data.book}`} target="_blank">
                      ladda ner bok
                    </a>
                  ) : (
                    <a href={`/books/${data.book}`} target="_blank">
                      تحميل الكتاب
                    </a>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Book;
