import React,{useState,useEffect} from 'react';
import './post-page.styles.scss';
import { Container, Row, Col,Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import Comments from '../comments/comments.component';
import { useLocation } from "react-router-dom";
import {queryString} from "querystring";
import {FacebookShareButton,LinkedinIcon,InstapaperIcon,WhatsappIcon,TwitterIcon,InstapaperShareButton,LinkedinShareButton,TwitterShareButton,WhatsappShareButton,FacebookIcon} from "react-share";
const PostPage = ({data,language}) =>{
    const app = useSelector(state=>state.app);
    console.log(data);
    const videolink = "https://www.youtube.com/embed/"+data.video;
    const shareUrl = 'https://kamosarabi.se/عربي_في_السويد?title='+data.Title;
    let title=data.Title;
    

return(
 <div className="staticPage">
     
     <div className="headerimage"></div>
     <Row className="introduction">
      <Col className='ar ar-regular' dangerouslySetInnerHTML={{__html: data.Introduction_ar}}></Col>
 <iframe width="100%" height="315" src={videolink} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
     </Row>
     {(data.Description) && 
     <Row>
    <Col className='ar ar-light' dangerouslySetInnerHTML={{__html: data.Description}}></Col>
     </Row>
     }
<FacebookShareButton
            url={shareUrl}
            quote={title}
            picture={'https://kamosarabi.se/static/media/logo.c490fa14.png'}
            className="Demo__some-network__share-button"
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton> 
          &nbsp;&nbsp;   
          <TwitterShareButton
            url={shareUrl}
            title={title}
            className="Demo__some-network__share-button"
          >
            <TwitterIcon size={32} round />
          </TwitterShareButton>
          &nbsp;&nbsp;
          <WhatsappShareButton
            url={shareUrl}
            title={title}
            separator=":: "
            className="Demo__some-network__share-button"
          >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
         &nbsp;&nbsp;
          <LinkedinShareButton url={shareUrl} className="Demo__some-network__share-button">
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
     
     {(data.id==4) && <Comments />}
 </div>
)   
}

export default PostPage;