const AppActionTypes = {
  FETCH_OFFERS_START: "FETCH_OFFERS_START",
  FETCH_OFFERS_SUCCESS: "FETCH_OFFERS_SUCCESS",
  FETCH_OFFERS_FAILURE: "FETCH_OFFERS_FAILURE",
  SET_ADD_TO_HOME_SCREEN: "GET_ADD_TO_HOME_SCREEN",
  SET_ADD_TO_HOME_SCREEN_COUNTER: "GET_ADD_TO_HOME_SCREEN_COUNTER",
  GET_SERVER_URL: "GET_SERVER_URL",
  SET_MARKET_NAME: "SET_MARKET_NAME",
  SET_STORE_INFO: "SET_STORE_INFO",
  CLEAR_ORDER_DATA: "CLEAR_ORDER_DATA",
  SET_RECEIVE_NOTIFICATION_TRUE: "SET_RECEIVE_NOTIFICATION_TRUE",
  SET_OPENED_CATEGORY: "SET_OPENED_CATEGORY",
  UPDATE_SERVER_URL: "UPDATE_SERVER_URL",
  SET_TRANSLATION_TYPE: "SET_TRANSLATION_TYPE",
  CLEAR_RESULTS: "CLEAR_RESULTS",
  ADD_OPENING: "ADD_OPENING",
  TOKEN: "TOKEN",
};

export default AppActionTypes;
