import React, { useState, useEffect } from "react";
import "./comments.styles.scss";
import { Container, Row, Col } from "reactstrap";
import { useSelector } from "react-redux";
import InfoSection from "../info-section/info-section.component";
import AddComment from "../add-comment/add-comment.component";
import { token } from "../constants/index";

const Comments = () => {
  const app = useSelector((state) => state.app);
  const [comments, setComments] = useState([]);
  useEffect(() => {
    if (comments.length == 0) {
      fetch(`${app.serverUrl}/comments`, {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          Authorization: token,
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          console.log("response", response);
          setComments(response);
        });
    }
  }, [comments]);

  return (
    <div className="Comments">
      <Container>
        <AddComment />
        <InfoSection arabic="التعليقات" swedish="Kommentarer" />
        <div className="CommentScroll">
          {comments.map((element, index) => (
            <div className="CommentDiv" key={`comment_${index}`}>
              <Row className="commentbox">
                <Col className={"ar ar-regular"}>{element.body}</Col>
              </Row>
              <Row className={"ar ar-regular commentWriter"}>
                <Col className={"ar ar-regular"}>{element.writer}</Col>
              </Row>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default Comments;
