import React, { useState } from "react";
import "./reset-password.styles.scss";
import { Container } from "reactstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import { insertToken } from "../../redux/app/app.actions";
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

function ResetPassword() {
  const app = useSelector((state) => state.app);
  const [error, setError] = useState();
  const dispatch = useDispatch();
  const [resetRequested, setResetRequested] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get('email');
  const code = searchParams.get('code');
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (values) => {

    if (values.password !== values.confirmPassword) {
      setError(`Password didn't match / كلمة المرور غير متطابقة`);
      return;
    }
    delete values.confirmPassword;
    fetch(`${app.serverUrl}/users/reset-password`, {
      method: "POST",
      body: JSON.stringify({email : email, code : code, password : values.password}),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if(response===1){
        setResetRequested(true);
        setTimeout(()=> history.push('/'), 3000)
        }
else{
  setError('Something went wrong, please try again - حدث خطأ ما، يرجى المحاولة مرة أخرى ')
}
      }); 
  };
    
  if(resetRequested){
   return (
    <div className="Login">
      <Container>
                            <div className="note">Password has been changed, please login using the new one - لقد تم تغيير كلمة المرور، يرجى تسجيل الدخول باستخدام كلمة المرور الجديدة</div>
     </Container>
</div>
   )
  };

  return (
    <div className="Login">
      <Container>
        <>
          <div
            className="askDescription"
            style={{ textAlign: "right", marginBottom: 30 }}
          >
            Please enter your new Password / الرجاء إدخال كلمة المرور الجديدة الخاصة بك{" "}
          </div>
          <div className="questionForm">
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                flexWrap: "wrap",
              }}
            >
              <input
                name="password"
                className="InputEmail Input"
                placeholder="New password - كلمة المرور الجديدة"
                required
                {...register("password")}
type="password"
                style={{
                  width: "49%",
                  margin: "0px 5px",
                  textAlign: "right",
                  direction: "rtl",
                  border: "1px solid #373737",
                  borderRadius: 10,
                  padding: 5,
                  height: "50px",
                }}
              />
  <input
                name="confirmPassword"
                className="InputEmail Input"
                placeholder="Confirm new password - تأكيد كلمة المرور الجديدة"
                required
                {...register("confirmPassword")}
type="password"
                style={{
                  width: "49%",
                  margin: "0px 5px",
                  textAlign: "right",
                  direction: "rtl",
                  border: "1px solid #373737",
                  borderRadius: 10,
                  padding: 5,
                  height: "50px",
                }}
              />
              <div className="error">{error && error}</div>
                            <div className="note">We process your personal data in accordance with the General Data Protection Regulation (GDPR)</div>
              <div className="SubmitComment" style={{ marginTop: 30 }}>
                <Button>Submit - ارسال</Button>
              </div>
            </form>
          </div>
        </>
      </Container>
    </div>
  );
}

export default ResetPassword;
