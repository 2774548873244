import React, { useState, useRef, useEffect } from "react";
import "./arabic-in-sweden-introduction.styles.scss";
import { Container, Row, Col, Button } from "reactstrap";
import { useSelector } from "react-redux";
import SwedenBanner from "../../assets/arabs_in_sweden_logo.png";

const ArabsinSwedenIntroduction = ({ data, language }) => {
  const app = useSelector((state) => state.app);
  const [introduction, Setintroduction] = useState([]);
  const myRef = useRef(null);
  useEffect(() => {
    if (introduction.length == 0) {
      fetch(`${app.serverUrl}/sections/7`, {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          Setintroduction(response);
          console.log("response", response.id);
        });
    }
  }, [introduction]);
  return <div></div>;
};

export default ArabsinSwedenIntroduction;
