import React, { useState, useEffect } from 'react';
import './post.styles.scss';
import { useSelector } from 'react-redux'
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PostPage from '../post-page/post-page.component';
import { useLocation } from "react-router-dom";


const Post = ({ data }) => {
    const [posts, setPosts] = useState([]);
    const app = useSelector(state => state.app);
    //console.log('data',data);
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const params = useLocation();


    useEffect(() => {
        const newparams = new URLSearchParams(params.search);
        const title_url = newparams.get('title'); // bar
        //  console.log('title_url',title_url);
        if (title_url) { if (data.Title == title_url) { setModal(true); } }
    }, [data]);



    return (
        <div className="PostModal">
            <div className="Post">
                <div className="image" onClick={toggle}>

                    {data.Images[0] &&
                        <>
                            {'image', console.log(data.Images[0])}
                            <img src={app.serverUrl + data.Images[0].url} />
                        </>
                    }
                </div>
                <div className="description">
                    <h3>{data.Title}</h3>

                    <div dangerouslySetInnerHTML={{ __html: data.Description }}></div>
                </div>
                <div className="btnBox"><Button onClick={toggle} className="ViewBtn ar ar-regular">Mer-المزيد</Button></div>

            </div>
            <Modal isOpen={modal} toggle={toggle} className='ModalBox modal-lg'>
                <ModalHeader className={(data.Language == "Swedish") ? 'swedish se se-bold' : 'arabic ar ar-bold'}>{data.Title}</ModalHeader>
                <ModalBody><PostPage data={data} /></ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default Post;