import React, { useState, useRef, useEffect } from "react";
import "./about-us.styles.scss";
import { Container, Row, Col, Button } from "reactstrap";
import { useSelector } from "react-redux";
import DoctorHussam from "../../assets/doctor_hussam.png";
import InfoSection from "../info-section/info-section.component";
const AboutUs = ({ data, language }) => {
  const app = useSelector((state) => state.app);
  const [aboutus, Setaboutus] = useState([]);
  const myRef = useRef(null);
  useEffect(() => {}, [aboutus]);
  return (
    <div className="AboutUs" classID>
      <Container>
        <div className="AboutTitle">
          <div className="left">Om oss</div>
          <div className="right">من نحن</div>
        </div>
        <Row>
          <Col xs={12} md={6} className="bodysec">
            <div className="aboutUs_sec_2 ar ar-light">
              <p>المؤلف</p>
              <span className="author_name">حسام القبلاوي</span> فلسطيني الأصل،
              لبناني المولد سويدي الجنسية.
              <br />
              <br />
              ولد في بيروت لؤلؤة الشرق حيث أنهى دراسته المدرسية في مدرسة الفاروق
              عمر بن الخطاب التابعة لجمعية المقاصد الإسلامية. حاصل على مؤهلات
              علمية عُليا من القاهرة والسويد. حاصل على درجة البكالوريوس في
              العلوم و .درجة الماجستير و الدكتوراه في علم المعادن
              <br />
              <br />
            </div>
            <div className="aboutUs_sec_2 ar ar-light">
              عمل مدرساً وباحثاً في معهد علوم الأرض، كلية العلوم، جامعة لوند.
              عمل أيضاً مدرساً لمادة الترجمة في كلية الآداب واللغات، جامعة لوند
              ومحاضراً في قانون المدارس للوافدين الجُدد من المعلمين في كلية
              المُعلمين جامعة مالمو. في العام 1992 أصبح أول مترجم محلف بين
              السويدية والعربية في منطقة جنوب السويد ليصبح فيما بعد مترجماً
              محلفاً متخصصاً في القانون ويعمل مدرساً للترجمة ومحاضراً في قضايا
              المجتمع وتلاقي الحضارات و حاصل على دورات حرة في دراسات الشرق
              الأوسط و عادات الشعوب.
              <br />
              <br />
              يعمل في مجال الكتابة و التأليف حيث أصدر العديد من القواميس والكتب
              المتخصصة والروايات.
              <br />
              <br />
              أول من أسس مدرسة الترجمة في مدرسة ستودية فراميندت بالتعاون مع
              إدارة المدرسة في مالمو.
              <br />
              <br />
              عمل مدرساً و خبيراً لغوياً لمادة الترجمة في مدرسة ميدبوريار سكولان
              في مالمو و مدارس أخرى في مدن مختلفة
              <br />
              <br />
              من الرواد الأوائل المشاركين في تأسيس مهرجان مالمو للسينما العربية
              <br />
              <br />
              مؤسس و رئيس اول حزب سياسي في السويد تتكون قيادته بأكملها من
              سويديين من أصول اجنبية
              <br />
              <br />
              مؤسسس و مالك مجموعة ايسترن ستار للانتاج الفني و كذلك سلسلة متاجر
              ميدو التي نالت على إعجاب و اطراء رئيس وزراء السويد الأسبق يوران
              بيرسون مؤسس ومالك دار الشمال للنشر و التوزيع
            </div>
          </Col>
          <Col xs={12} md={6}>
            <img src={DoctorHussam} width="100%" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AboutUs;
