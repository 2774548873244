import React, { useState } from "react";
import "./forgot-password.styles.scss";
import { Container } from "reactstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import { insertToken } from "../../redux/app/app.actions";

function ForgotPassword() {
  const app = useSelector((state) => state.app);
  const [error, setError] = useState();
  const dispatch = useDispatch();
  const [resetRequested, setResetRequested] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (values) => {
    fetch(`${app.serverUrl}/users/forgot-password`, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {

          if(response===1){
          setResetRequested(true);
          }
else{
  setError('Something went wrong, please try again and check your email correctly - حدث خطأ ما، يرجى المحاولة مرة أخرى والتحقق من بريدك الإلكتروني بشكل صحيح')

}

      });
  };
    
  if(resetRequested){
   return (
    <div className="Login">
      <Container>
                            <div className="note">Please check your email for reset link - يرجى التحقق من بريدك الإلكتروني للحصول على رابط إعادة التعيين</div>
     </Container>
</div>
   )
  };

  return (
    <div className="Login">
      <Container>
        <>
          <div
            className="askDescription"
            style={{ textAlign: "right", marginBottom: 30 }}
          >
            Please enter your email / الرجاء ادخال البريد الالكتروني{" "}
          </div>
          <div className="questionForm">
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                flexWrap: "wrap",
              }}
            >
              <input
                name="email"
                className="InputEmail Input"
                placeholder="البريد الالكتروني"
                required
                {...register("email")}
                style={{
                  width: "49%",
                  margin: "0px 5px",
                  textAlign: "right",
                  direction: "rtl",
                  border: "1px solid #373737",
                  borderRadius: 10,
                  padding: 5,
                  height: "50px",
                }}
              />
              <div className="error">{error && error}</div>
                            <div className="note">We process your personal data in accordance with the General Data Protection Regulation (GDPR)</div>
              <div className="SubmitComment" style={{ marginTop: 30 }}>
                <Button>Reset Password - طلب كلمة سر جديدة</Button>
              </div>
            </form>
          </div>
        </>
      </Container>
    </div>
  );
}

export default ForgotPassword;
