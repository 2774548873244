import React from 'react';
import './Logo.styles.scss';
import logo from '../../assets/logo.png';
function Logo(){

    return(
        <div className="LogoDiv">
            <img src={logo} />
        </div>
        
    )
}

export default Logo;