import React,{useState,useEffect} from 'react';
import './posts.styles.scss';
import { Container, Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux'
import {token} from '../constants/index';
import Post from '../post/post.component';


const Posts = () =>{
    const [posts, setPosts] = useState([]);
    const app = useSelector(state => state.app);

    useEffect(() => {
        if(posts.length==0){
          fetch(
              `${app.serverUrl}/posts`,
              {
                method: "GET",
                headers: new Headers({
                  Accept: "application/json",
                  'Authorization': token,

                })
              }
            )
              .then(res => res.json())
              .then(response => {
                //console.log('response',response)
                setPosts(response);
              })
            }
  
      },[posts]);

    return(
        <div className="Posts">
        <Container>
         <Row>
           {posts.map((element)=>
            <Col xs={12} md={3} key={`posts_${element.id}`}><Post data={element}  language="arabic" /></Col>
           
         )}
         </Row>
        </Container>
      </div>
    )
}

export default Posts;