import React,{useState,useEffect} from 'react';
import './info-section.styles.scss';
import { Container, Row, Col } from 'reactstrap';
import Logo from '../logo/Logo.component';
import { FaHome,FaHeadphones,FaBookReader,FaUserAlt } from "react-icons/fa";


const InfoSection = ({arabic,swedish}) =>{
    return(
      <div className="infoSection">
        <Container >
            <Row>
    <Col><div className="se se-bold">{swedish}</div></Col>
    <Col><div className="ar ar-bold">{arabic}</div></Col>

            </Row>
        </Container>
        </div>
    )
}

export default InfoSection;