import React, { useState, useEffect } from "react";
import "./arabic-to-swedish.styles.scss";
import Autocomplete from "react-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { SetSearchResult } from "../../redux/translator/translator.actions";
import { addOpening } from "../../redux/app/app.actions";
import { token } from "../constants/index";
import { cleanText, stripHtmlTags } from "../../utils/index";
import { clearResults } from "../../redux/translator/translator.actions";

const ArabicToSwedish = () => {
  const app = useSelector((state) => state.app);
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(SetSearchResult(""));
    setSuggestions([]);
    if (value.length > 0) {
      const timer = setTimeout(() => {
        fetch(`${app.serverUrl}/phrases/search/startWith/arabic/${value}`, {
          method: "GET",
          headers: new Headers({
            Accept: "application/json",
            Authorization: token,
          }),
        })
          .then((res) => res.json())
          .then((response) => {
            dispatch(addOpening());
            let newsuggestions = [];

            let filteredResults = response.filter((val) =>
              String(val.arabic.replace(/<[^>]+>/g, "")).startsWith(value)
            );

            filteredResults.map((element) => {
              newsuggestions.push({
                label: cleanText(element.arabic),
                id: element.id,
              });
            });
            //console.log('response',response);
            setSuggestions(newsuggestions);
          });
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [value]);

  const searchByButton = () => {
    if (value) {
      const query = encodeURIComponent(value);
      fetch(`${app.serverUrl}/phrases/exact-phrase/arabic/${query}`, {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          Authorization: token,
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.length > 0) {
            console.log("responseresponse", response);
            dispatch(SetSearchResult(response));
          }
        });
    }
  };

  const clearText = () => {
    setValue("");
    dispatch(clearResults());
  };
  const search = (searchText) => {
    let id = searchText.substr(0, searchText.indexOf("*"));
    //console.log('id',id);
    let newSearchtext = searchText.substr(searchText.indexOf("*") + 1);
    setValue(newSearchtext);
    fetch(`${app.serverUrl}/phrases/${id}`, {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        Authorization: token,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        dispatch(SetSearchResult(response));
      });
  };

  return (
    <div className="arabictoswedish">
      <div className="autocomplete">
        <button className="searchBtn" onClick={searchByButton}>
          ابحث
        </button>
        {value.length > 0 && (
          <button className="closeBtn" onClick={clearText}>
            احذف النص المكتوب
          </button>
        )}
        <Autocomplete
          getItemValue={(item) => item.id + "*" + item.label}
          items={suggestions}
          renderItem={(item, isHighlighted) => {
            const styledLabel = item.label.replace(
              value,
              `<span class="bold">${value}</span>`
            );
            return (
              <div
                key={"autocomplete_" + Math.random()}
                style={{ background: isHighlighted ? "lightgray" : "white" }}
                className="ar ar-light insideautocomplete"
                dangerouslySetInnerHTML={{ __html: styledLabel }}
              ></div>
            );
          }}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onSelect={(val) => search(val)}
          key="field_1001"
          inputProps={{
            placeholder: "للبحث المتطور و للحصول على نتيجة أشمل اضغط ابحث",
          }}
        />
      </div>
    </div>
  );
};

export default ArabicToSwedish;
