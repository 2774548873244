import TranslatorTypes from "./translator.types";
import AppActionTypes from "../app/app.types";

const INITIAL_STATE = {
  searchResult: [],
};

export const clearResults = () => ({
  type: AppActionTypes.CLEAR_RESULTS,
});

const translatorReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TranslatorTypes.SET_SEARCH_RESULT:
      return {
        ...state,
        searchResult: action.payload,
      };
    case TranslatorTypes.CLEAR_RESULTS:
      return {
        ...state,
        searchResult: [],
      };

    default:
      return state;
  }
};

export default translatorReducer;
