var React = require('react');

const Template = () => {

  return (<div className="helpBody">

    <iframe width="100%" height="315" src="https://www.youtube.com/embed/82GKTk4Pglc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

    <h4>البحث من العربية للسويدية:</h4>

    البحث من اللغة العربية هو المهمة الأصعب لمحرك البحث، حيث يبحث المحرك عن الكلمة تماماً كما يكتبها الباحث أو بمعنى آخر هو يبحث عن صورة الكلمة كما كُتِبَت. هذا يعني عملياً أنك لو كتبت الكلمة بالتشكيل فإنه سيبحث عنها كما كُتَبت بعلامات التشكيل:
    <br /><br />
    مثال: لو كتبت كلمة (دَفْتَرٌ) فالمحرك سيبحث عن هذه الكلمة تحديداً مما يعني أنك لم تحصل على نتائج إذا كانت الكلمة داخل القاموس مكتوبة بأحد الطُرق التالية:
    <br /><br />
    دفتر، دَفتر، دَفْتَر ... إلخ
    <br /><br />
    لتسهيل عملية البحث كتبتنا لذلك معظم الكلمات دون تشكيل أي "دفتر".
    <br /><br />
    لكننا لم نستطع التغاضي عن وضع علامات التشكيل فوق الكلمة عندما كان هذا الأمر ضرورياً، مثال:
    كُتِبَت الرسالة بالخط العريض.
    <br /><br />
    لأننا لو كتبناها دون تشكيل فربما يقرأها الباحث كَتَبْتُ.
    ننصح لذلك أنك بالدرجة الأولى تكتب الكلمة المُراد البحث عنها دون علامات التشكيل، فإن لم تجدها فحاول بعد ذلك بالتشكيل.
    <br /><br />
    الأصعب كان هو استخدام الهمزة على الألف، مثلاً (أ، إ). لأننا نعلم أن معظم الباحثين يكتبون الألف بهذا الشكل (ا) ففضلنا استخدام هذا الشكل من الألف في معظم الكلمات حتى و إن كان يجب كتابتها بالأشكال التالية (أ، إ).
    <br /><br />
    قمنا بهذا التجاوز لتسهيل عملية البحث.
    <br /><br />
    مثلاً: إن أردت البحث عن كلمة (أوكسجين) فستجدها غالباً مكتوبة (اوكسجين)
    و لكن ان لم تعثر على مُرادك فلا مانع من أن تكتب (أوكسجين) فربما تحصل على نتائج أكثر. هذه الطريقة تسري على معظم الكلام في هذا القاموس.
    <br /><br /><br /><br />
    <h4>كيفية البحث:</h4>
    عند كتابتك للأحرف الثلاثة الأولي من الكلمة المُراد البحث عنها سيبدأ القاموس بعرض قائمة طويلة تبدأ بهذه الأحرف الثلاثة. كلما أضفت حرفاً تغيرت قائمة المقترحات لتعرض عليك الكلمات التي تبدأ بهذا العدد من الأحرف التي كتبتها. عندما تكتب الكلمة التي تبحث عنها بشكل كامل ستظهر لك في هذه الحالة قائمة تحتوي على كل الكلمات التي تبدأ بهذه الكلمة.
    <br /><br />
    اضغط على الكلمة التي تبحث عنها و ستظهر لك النتيجة بالسويدية
    <br /><br /><br /><br />
    <h4>ملاحظة:</h4>
    ان الضغطة الأولى على الكلمة ستزيل لوحة المفاتيح عن الشاشة أما الضغطة الثانية سَتُظْهِر لك معنى الكلمة التي تبحث عنها بالسويدية
    <br /><br />
    انما لو ضغط على مفتاح البحث المتطور (ابحث) ستظهر لك قائمة تحتوي على كل الكلمات أو الجُمَل التي تدخل بها الكلمة التي تبحث عنها، سواء كانت في البداية أو المنتصف أو النهاية أو حتى لو كانت جزء من مصطلح أكبر أو جملة أو تعبير.
    <br /><br /><br /><br />
    <h4>ملاحظة:</h4>

    هنا ستظهر لك قائمة بكل مقترحات الكلمة التي تبحث عنها بالعربية مع معناها باللغة السويدية في آن واحد. أي أنك لست بحاجة للضغط على الكلمة المُراد معرفة معناها بالسويدية
    <br /><br /><br /><br />
    <h4>البحث من السويدية للعربية:</h4>

    الوضع في هذه الحالة أبسط بكثير عنه عند البحث من العربية للسويدية. محرك البحث سيعثر على الكلمة التي تبحث عنها سواء كتبتها بالأحرف الكبيرة أو الصغيرة.
    <br /><br />
    حتى المختصرات المكونة فقط من حرفين ستظهر لك سواء كتبتها بالأحرف الكبيرة أو الصغيرة
    <br /><br />
    عند كتابتك لأول حرفين من الكلمة المُراد البحث عنها سيبدأ القاموس بعرض قائمة طويلة تبدأ بهذين الحرفين. كلما أضفت حرفاً تغيرت قائمة المقترحات لتعرض عليك الكلمات التي تبدأ بهذا العدد من الأحرف التي كتبتها. عندما تكتب الكلمة التي تبحث عنها بشكل كامل ستظهر لك في هذه الحالة قائمة تحتوي على كل الكلمات التي تبدأ بهذه الكلمة.
    <br /><br />
    اضغط على الكلمة التي تبحث عنها و ستظهر لك النتيجة باللغة العربية
    <br /><br /><br /><br />
    <h4>ملاحظة: </h4>

    ان الضغطة الأولى على الكلمة ستزيل لوحة المفاتيح عن الشاشة أما الضغطة الثانية سَتُظْهِر لك معنى الكلمة التي تبحث عنها باللغة العربية
    <br /><br />
    انما لو ضغط على مفتاح البحث المتطور ستظهر لك قائمة تحتوي على كل الكلمات أو الجُمَل التي تدخل بها الكلمة التي تبحث عنها، سواء كانت في البداية أو المنتصف أو النهاية أو حتى لو كانت جزء من مصطلح أكبر أو جملة أو تعبير.
    <br /><br /><br /><br />
    <h4>ملاحظة:</h4>

    هنا ستظهر لك قائمة بكل مقترحات الكلمة التي تبحث عنها بالسويدية مع معناها باللغة العربية في آن واحد. أي أنك لست بحاجة للضغط على الكلمة المُراد معرفة معناها بالعربية
    <br /><br /><br /><br />
    <h4>تنويه هام:</h4>

    لاحظ أنك عندما تضغط (ابحث) فإن الضغطة الأولى ستزيل لوحة المفاتيح عن الشاشة أما الضغطة الثانية تُظْهِر النتيجة.
    <br /><br />
    لاحظ دائماً أن ظهور لوحة المفاتيح على الشاشة يحتاج منك لضغطة لإزالة اللوحة و ضغطة ثانية لتفعيل البحث سواء عند اختيارك للكلمة التي تبحث عنها أو عند ضغطك على مفتاح (ابحث)



  </div>);

};

export default Template;
